import React from 'react';
import { Link } from 'gatsby';

import { titleCase } from '../../utils/title-case';
import iconPlayAlt from '../../images/podcast-play.png';

export default function PodCastItem(props) {
  const {
    data: { podCastTitle, thumnailImage, podcastDuration },
    uid,
  } = props;

  return (
    <div className="cols watch_item-list ">
      <Link className={`watch_item-figure media podcast-card`} to={uid}>
        <div className="m_overlay" />
        <div className="m_overlay-image bgImage" style={{ backgroundImage: `url(${thumnailImage.url})` }}></div>
        <div className="media-content">
          <div
            className="btn btn-watch-play btn-transparent"
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.17)',
            }}
          >
            <img src={iconPlayAlt} alt="Icon Play" className="icon-play-alt" style={{ height: 17, width: 21 }} />
          </div>
        </div>
        {true && (
          <div className="m_run-time m_run-time-outer">
            <span>{podcastDuration}</span>
          </div>
        )}
        <div className="m_border"></div>
      </Link>
      <span className="watch_item-title">{titleCase(podCastTitle[0].text)}</span>
    </div>
  );
}

